import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  refreshUserMasterDetails,
  updatePersistIdNumbersAction,
  updatePrivacyModeAction,
  updateUserMaster
} from 'stores/app';
import c from 'util/const';

const TimezoneSettings = () => {
  const { userMaster } = useSelector(state => state.app);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    LocalTimezone: userMaster?.LocalTimezone,
    isUpdating: false
  });
  const timezones = c.timezones;

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormData({
      ...formData,
      isUpdating: true
    });
    let updatedUserMaster = Object.assign({}, userMaster);
    updatedUserMaster.LocalTimezone = formData.LocalTimezone;
    dispatch(updateUserMaster(userMaster?.GUID, updatedUserMaster))
      .then(res => {
        setFormData({
          ...formData,
          isUpdating: false
        });
        toast.success(`Timezone updated successfully.`, {
          theme: 'colored'
        });
        dispatch(refreshUserMasterDetails(userMaster?.GUID));
      })
      .catch(err => {
        setFormData({
          ...formData,
          isUpdating: false
        });
      });
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Default Timezone" />
      <Card.Body className="bg-light">
        <div className="ps-2">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="d-flex" lg={12}>
                <Form.Group as={Col} lg={12} controlId="LocalTimezone">
                  <Form.Label>Timezone Settings</Form.Label>
                  <Form.Select
                    type="text"
                    name="LocalTimezone"
                    onChange={handleChange}
                    defaultValue={formData.LocalTimezone}
                  >
                    <option value="">Select timezone</option>
                    {timezones.map(option => (
                      <option value={option.offset} key={option.abbreviation}>
                        {option.name} ({option.offset})
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col className="d-flex mt-3 justify-content-end" lg={12}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={formData.isUpdating}
                >
                  {formData.isUpdating ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TimezoneSettings;
