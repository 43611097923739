import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import {
  faCalendarAlt,
  faClock,
  faFolder,
  faGavel,
  faGlobeAmericas,
  faHammer,
  faIdBadge,
  faLocationArrow,
  faPaperPlane,
  faSignature,
  faUser,
  faWeight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import store from 'app/store';

import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Button, Card, Col, Image, Row } from 'react-bootstrap';
import enums from 'util/enums';
import filters from 'util/filters';
import logger from 'util/logger';
import userHelper from './userHelper';

const getSignatureViewCards = (title, imageData) => {
  return imageData ? (
    <Col lg={4} className="mb-3">
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <Flex
            justifyContent="between"
            alignContent="center"
            alignItems="center"
          >
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <FontAwesomeIcon icon={faIdBadge} size="lg" className="me-3" />
              <h5 className="mb-0 mb-1">{title}</h5>
            </Flex>
          </Flex>
          <hr className="my-0" />
        </Card.Header>

        <Card.Body as={Flex} justifyContent="between" className="pt-1">
          <div className="fs--1">
            <img
              style={{ maxHeight: 150, maxWidth: '100%' }}
              src={'data:image/png;base64,' + imageData}
            />
          </div>
        </Card.Body>
      </Card>
    </Col>
  ) : (
    <></>
  );
};

const getSignatureViewImage = (
  imageData,
  maxWidth,
  maxHeight,
  className = ''
) => {
  return imageData ? (
    <img
      className={className}
      style={{
        maxHeight: maxHeight ? maxHeight : 'auto',
        maxWidth: maxWidth ? maxWidth : 'auto'
      }}
      src={'data:image/png;base64,' + imageData}
    />
  ) : (
    <></>
  );
};

const getFingerprintViewImage = (
  imageData,
  maxWidth,
  maxHeight,
  className = ''
) => {
  return imageData ? (
    <img
      className={className}
      style={{
        maxHeight: maxHeight ? maxHeight : 'auto',
        maxWidth: maxWidth ? maxWidth : 'auto'
      }}
      src={'data:image/png;base64,' + imageData}
    />
  ) : (
    <></>
  );
};

const getWitnessAddressViewCards = address => {
  return address ? (
    <Col lg={4} className="mb-3">
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <Flex
            justifyContent="between"
            alignContent="center"
            alignItems="center"
          >
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <FontAwesomeIcon icon={faIdBadge} size="lg" className="me-3" />
              <h5 className="mb-0 mb-1">Witness Address</h5>
            </Flex>
          </Flex>
          <hr className="my-0" />
        </Card.Header>

        <Card.Body as={Flex} justifyContent="between" className="pt-1">
          <div className="fs--1">
            {address?.address_1 && (
              <p className="mb-1">
                <span className="fw-semi-bold">Address line 1:</span>{' '}
                {address.address_1}
              </p>
            )}
            {address?.address_2 && (
              <p className="mb-1">
                <span className="fw-semi-bold">Address line 2:</span>{' '}
                {address.address_2}
              </p>
            )}
            {address?.city && (
              <p className="mb-1">
                <span className="fw-semi-bold">City</span> {address.city}
              </p>
            )}
            {address?.state && (
              <p className="mb-1">
                <span className="fw-semi-bold">State:</span> {address.state}
              </p>
            )}
            {address?.zip && (
              <p className="mb-1">
                <span className="fw-semi-bold">Zip:</span> {address.zip}
              </p>
            )}
            {address?.notes && (
              <p className="mb-1">
                <span className="fw-semi-bold">Notes:</span>{' '}
                <i className="fs--1">{address.notes}</i>
              </p>
            )}
          </div>
          <div></div>
        </Card.Body>
      </Card>
    </Col>
  ) : (
    <></>
  );

  // <div>
  //   <Button variant='outline-primary' onClick={() => openLocalIdentityViewModel(`${formTitle}'s Identity`, identification)}><FontAwesomeIcon icon={faIdBadge} /> View Identification <SoftBadge>{identification.length}</SoftBadge></Button>
  // </div> : <></>;
};

const getWitnessViewCard = (witness, witnessIdentification) => {
  return (
    <Row>
      <Col sm={12} md={4}>
        <Card className="h-md-100">
          <Card.Header>
            {filters.formatNameObj(witness.witness_name)}
          </Card.Header>

          {!!witness.location && (
            <Card.Body>
              <p className="mb-1">
                {witness.location.address_1} {witness.location.address_2}
              </p>
              <p className="mb-1">
                {witness.location.city}, {witness.location.state},{' '}
                {witness.location.zip}
              </p>
            </Card.Body>
          )}
        </Card>
      </Col>

      {witnessIdentification &&
        draftEntryUiHelper.getIdentificationViewCard(
          witnessIdentification,
          false,
          4
        )}
      {!!(witness && witness.signature) && (
        <Col sm={12} md={4}>
          <Card className="h-md-100">
            <Card.Header>Signature</Card.Header>
            <Card.Body className="text-center">
              {draftEntryUiHelper.getSignatureViewImage(
                witness.signature.data,
                180,
                200
              )}
            </Card.Body>
          </Card>
        </Col>
      )}
    </Row>
  );
};

const getIdentificationViewCard = (identity, removeMethod, col) => {
  //logger.log('getIdentificationViewCard', identity);
  return identity ? (
    <Col
      lg={col ? col : 4}
      className="mb-3"
      key={_.uniqueId('identification-view') + identity.identification_id}
    >
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <Flex
            justifyContent="between"
            alignContent="center"
            alignItems="center"
          >
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <FontAwesomeIcon icon={faIdBadge} size="lg" className="me-3" />
              <h5 className="mb-0 mb-1">
                Identification
                <SoftBadge
                  pill
                  bg={
                    identity.id_type === enums.ID_TYPES.NONE
                      ? 'warning'
                      : 'success'
                  }
                  className="ms-2 fs--2"
                >
                  {identity.id_type}
                </SoftBadge>
              </h5>
            </Flex>
            {removeMethod && (
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => removeMethod(identity.identification_id)}
              >
                Remove
              </Button>
            )}
          </Flex>
          <hr className="my-0" />
        </Card.Header>

        <Card.Body as={Flex} justifyContent="between" className="pt-1">
          <div className="fs--1">
            <p className="mb-1">
              <span className="fw-semi-bold">Name:</span> {identity.first_name}{' '}
              {identity.last_name}{' '}
            </p>
            {identity.id_number && (
              <p className="mb-1">
                <span className="fw-semi-bold">Id Number:</span>{' '}
                {/* {identity.id_number} */}
                {(identity?.id_state && identity?.id_state?.length > 0
                  ? `${identity?.id_state} `
                  : ``) +
                  (identity.id_number?.length > 0
                    ? `${identity.id_number}`
                    : 'N/A')}
              </p>
            )}
            {identity.id_issued_date && (
              <p className="mb-1">
                <span className="fw-semi-bold">Issue Date:</span>{' '}
                {draftEntryUiHelper
                  .getDateTimeWithTimeZone(identity.id_issued_date)
                  .format('MM/DD/YYYY')}
              </p>
            )}
            {identity.id_expiration_date && (
              <p className="mb-1">
                <span className="fw-semi-bold">Expiration Date:</span>{' '}
                {draftEntryUiHelper
                  .getDateTimeWithTimeZone(identity.id_expiration_date)

                  .format('MM/DD/YYYY')}
              </p>
            )}
            {identity.date_of_birth && (
              <p className="mb-1">
                <span className="fw-semi-bold">Date of Birth:</span>{' '}
                {draftEntryUiHelper
                  .getDateTimeWithTimeZone(identity.date_of_birth)
                  .format('MM/DD/YYYY')}
              </p>
            )}
            <p className="mb-1">
              <span className="fw-semi-bold">Address:</span>{' '}
              {getIdentityAddress(identity) || 'N/A'}
            </p>
            {identity.notes && (
              <p className="mb-1">
                <span className="fw-semi-bold">Notes:</span>{' '}
                <i className="fs--1">{identity.notes}</i>
              </p>
            )}
          </div>
          <div></div>
        </Card.Body>
      </Card>
    </Col>
  ) : (
    <></>
  );
};

const getIdentityAddress = entry => {
  //logger.log("entry", entry);
  return entry ? (
    <>
      {entry.id_address_1 && entry.id_address_1.length > 0
        ? entry.id_address_1 + ' '
        : ''}{' '}
      {entry.id_address_2 && entry.id_address_2.length > 0
        ? entry.id_address_2 + ' '
        : ''}
      {entry.id_city && entry.id_city.length > 0 ? entry.id_city + ', ' : ''}
      {entry.id_state && entry.id_state.length > 0 ? entry.id_state + ', ' : ''}
      {entry.id_postal_code && entry.id_postal_code.length > 0
        ? entry.id_postal_code
        : ''}
    </>
  ) : (
    <></>
  );
};

const grabIconFromString = string => {
  string = string.toLowerCase();
  let icon = faPaperPlane;
  let activity = 'Activity';
  if (string.indexOf('document') >= 0) {
    icon = faFolder;
    activity = 'Document';
  } else if (string.indexOf('timezone') >= 0) {
    icon = faGlobeAmericas;
    activity = 'Timezone';
  } else if (string.indexOf('profile') >= 0) {
    icon = faGavel;
    activity = 'Notary commission';
  } else if (string.indexOf('signing package') >= 0) {
    icon = faFolderOpen;
    activity = 'Signing Package';
  } else if (string.indexOf('date & time') >= 0) {
    icon = faClock;
    activity = 'Notarization Date Time';
  } else if (string.indexOf('created') >= 0) {
    icon = faCalendarAlt;
    activity = 'Started';
  } else if (string.indexOf('fingerprint') >= 0) {
    icon = faWeight;
    activity = 'FingerPrint';
  } else if (string.indexOf('location') >= 0) {
    icon = faLocationArrow;
    activity = 'Location';
  } else if (string.indexOf('signature') >= 0) {
    icon = faSignature;
    activity = 'Signature';
  } else if (string.indexOf('signer') >= 0) {
    icon = faUser;
    activity = 'Signer';
  }
  return { icon: icon, activity: activity };
};

const parseAuditLogToTimeline = log => {
  let tileLineLog = [];
  if (log.length > 0) {
    _.map(log, l => {
      let a = draftEntryUiHelper.grabIconFromString(l.description);
      let item = {
        year: draftEntryUiHelper
          .getDateTimeWithTimeZone(l.timestamp)
          .format('MM/DD/YYYY'),
        date: draftEntryUiHelper
          .getDateTimeWithTimeZone(l.timestamp)
          .format('ddd hh:mm:ss A'),
        title: a.activity,
        description: l.description,
        icon: a.icon
      };
      tileLineLog.push(item);
    });
  }
  return tileLineLog;
};

const getDisplayAddress = address => {
  let { address_1, address_2, city, state, zip } = address;

  let hasAddressField = !!(
    (address_1 && address_1.length > 0) ||
    (address_2 && address_2.length > 0)
  );
  let hasAreaField = !!(
    (city && city.length > 0) ||
    (state && state.length > 0) ||
    (zip && zip.length > 0)
  );

  let result = '';
  if (hasAddressField) {
    result =
      (address_1 && address_1.length > 0 ? address_1 + ' ' : '') +
      (address_2 && address_2.length > 0 ? address_2 + ' ' : '');
  }
  if (hasAreaField) {
    result += city && city.length > 0 ? city + ', ' : '';
    result += state && state.length > 0 ? state + ', ' : '';
    result += zip && zip.length > 0 ? zip : '';
  }

  return _.trim(result, ' ,');
};

const getDateTimeWithTimeZone = (time, timezone) => {
  logger.log('timezone', timezone);
  let LocalTimezone = store?.getState()?.app.userMaster?.LocalTimezone;
  timezone =
    userHelper.getTimezoneObj(timezone)?.zone ||
    userHelper.getTimezoneObj(LocalTimezone)?.zone ||
    moment?.tz?.MomentTimezone?.name;
  logger.log('updated timezone', timezone);
  time = time || moment();
  return moment.tz(time, timezone);
};

const draftEntryUiHelper = {
  getSignatureViewImage,
  getFingerprintViewImage,
  getSignatureViewCards,
  getWitnessAddressViewCards,
  getIdentificationViewCard,
  getIdentityAddress,
  getWitnessViewCard,
  parseAuditLogToTimeline,
  grabIconFromString,
  getDisplayAddress,
  getDateTimeWithTimeZone
};

export default draftEntryUiHelper;
