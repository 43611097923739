import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import WeeklySales from './WeeklySales';

import AppContext from 'context/Context';
import _ from 'lodash';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshDashboardData } from 'stores/dashboard';
import ActiveDevices from './ActiveDevices';
import CurrentSubscriptionView from './CurrentSubscriptionView';
import DocumentTypes from './DocumentTypes';
import NotaryMonthlyFee from './NotaryFeeMonthly';
import SavedLocations from './SavedLocations';
import SigningPackages from './SigningPackages';
import SigningWidget from './SigningWidget';
import SubscriptionDaysCountdown from './SubscriptionDaysCountdown';
import TotalSales from './TotalSales';
import SessionByUses from './session-by-uses/SessionByUses';

import { faCircle } from '@fortawesome/free-solid-svg-icons';
import corner1 from 'assets/img/illustrations/corner-1.png';
import corner2 from 'assets/img/illustrations/corner-2.png';
import corner3 from 'assets/img/illustrations/corner-3.png';
import { stringToColour } from 'helpers/utils';
import ShowCounter from './ShowCounter';
import Greetings from './greetings/Greetings';
import IconAlert from 'components/common/IconAlert';
import moment from 'moment';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataFeeByCategory, setDataFeeByCategory] = useState(null);
  const [dataDocumentByType, setDataDocumentByType] = useState(null);
  const [daysRange, setDaysRange] = useState(0);

  const {
    config: { tourEnable },
    setConfig
  } = useContext(AppContext);
  const { userMaster, dataExportMonths } = useSelector(state => state.app);
  const { dashboard } = useSelector(state => state);
  const initialized = useRef(null);

  useEffect(() => {
    if (userMaster && !initialized.current) {
      initDashboardData();
      initialized.current = true;
    }
  }, [userMaster]);

  const initDashboardData = () => {
    setLoading(true);
    dispatch(refreshDashboardData())
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setDataFeeByCategory(
      _.orderBy(
        renderUsesData(dashboard?.rawData?.Summary?.FeesByCategory),
        ['value'],
        ['desc']
      )
    );
    setDataDocumentByType(
      _.orderBy(
        renderUsesData(dashboard?.rawData?.Summary?.DocumentsByType),
        ['value'],
        ['desc']
      )
    );
  }, [dashboard]);

  const renderUsesData = dataset => {
    let tempData = [];
    let counter = 0;
    let totalValue = 0;
    _.map(dataset, function (value, key) {
      totalValue += parseFloat(value);
      counter++;
    });
    let avg = totalValue / counter;

    _.map(dataset, function (value, key) {
      let progressValue = Math.round(parseInt(value) - avg, 1);
      let progressPercent = Math.round((progressValue / avg) * 100, 0);
      tempData.push({
        icon: faCircle,
        label: key,
        value: value,
        progress: progressValue >= 0,
        progressValue: progressValue,
        caret:
          parseFloat(progressValue) > 0
            ? 'fas fa-caret-up text-success'
            : 'fas fa-caret-down text-danger',
        color: stringToColour(key),
        progressPercent: progressPercent + '%'
      });
      //if (++counter > 2) return;
    });
    return tempData;
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col sm="12">
          <Greetings
            setLoading={setLoading}
            loading={loading}
            setDaysRange={setDaysRange}
          />
        </Col>
        {daysRange && daysRange != 7 && (
          <Col sm="12">
            <IconAlert variant={'info'}>
              <p className="mb-0">
                Large date ranges may take longer to load - please be patient
                while the dashboard populates with your data.
              </p>
            </IconAlert>
          </Col>
        )}
      </Row>
      <Row className="g-3 mb-3">
        <Col md={6} xl={3}>
          <WeeklySales
            loading={loading}
            data={_.countBy(
              _.orderBy(
                _.map(dashboard.draft, d => {
                  return {
                    name:
                      'Last updated on: ' +
                      moment(d.Updated).local().format('MM/DD/YYYY'),
                    created: moment(d.Created).local().format('MM/DD/YYYY'),
                    date: moment(d.created).local().format('MM/DD/YYYY')
                  };
                }),
                ['date'],
                ['asc']
              ),
              'created'
            )}
          />
        </Col>
        <Col md={6} xl={3}>
          <SigningPackages
            loading={loading}
            data={_.countBy(dashboard.signingPackage, 'created')}
          />
        </Col>
        <Col md={6} xl={3}>
          {/* <MarketShare data={marketShare} radius={['100%', '87%']} /> */}
          <DocumentTypes
            loading={loading}
            data={_.countBy(
              _.orderBy(
                _.map(dashboard.documentType, d => {
                  return {
                    name: d.name,
                    created: moment(d.created)
                      .local()
                      .format('MM/DD/YYYY ddd hh:mm:ss A'),
                    date: moment(d.created)
                  };
                }),
                ['date'],
                ['asc']
              ),
              'created'
            )}
          />
        </Col>
        <Col md={6} xl={3}>
          {/* <Weather data={weather} /> */}
          <SavedLocations
            loading={loading}
            data={_.countBy(dashboard.location, 'created')}
          />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col sm={6} md={4}>
          <SessionByUses
            loading={loading}
            data={dataFeeByCategory}
            title="Fees by Category"
            labelPrefix="$"
          />
        </Col>
        <Col sm={6} md={4}>
          <SessionByUses
            loading={loading}
            data={dataDocumentByType}
            title="Documents by Types"
            labelPrefix=""
          />
        </Col>
        <Col sm={6} md={4}>
          <Row>
            <Col md={12} className="mb-3 h-100">
              <ShowCounter
                loading={loading}
                title="Fee Collected"
                description="Total fees collected for the selected timeframe"
                background={corner1}
                prefix={'$'}
                data={{ total: dashboard?.rawData?.Summary?.FeesCollected }}
              />
            </Col>
            <Col md={12} className="my-2 h-100">
              <ShowCounter
                loading={loading}
                title="Documents in Journal"
                description="Total number of documents notarized for the selected timeframe"
                background={corner2}
                data={{ total: dashboard?.rawData?.Summary?.DocumentCount }}
              />
            </Col>
            <Col md={12} className="mt-3 h-100">
              <ShowCounter
                loading={loading}
                title="Signers in Journal"
                description="Total number of signers you notarized for the selected timeframe"
                background={corner3}
                data={{
                  total: dashboard?.rawData?.Summary?.SignerDistinctCount
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6} xl={6} xxl={5}>
          <CurrentSubscriptionView
            loading={loading}
            className="h-100"
            showSubscriptionLink={true}
          />
        </Col>
        <Col lg={6} xl={6} xxl={7}>
          <SubscriptionDaysCountdown loading={loading} className="h-100" />
        </Col>
      </Row>

      <Row className="g-3">
        <Col sm={6} lg={6} xl={6} xxl={6}>
          <ActiveDevices
            loading={loading}
            className="h-100"
            users={dashboard.device}
          />
        </Col>
        <Col sm={6} lg={6} xl={6} xxl={6} className="order-xxl-1">
          <SigningWidget loading={loading} width={500} />
        </Col>
      </Row>
      <Row className="g-3 mt-2">
        <Col lg={6} xl={6} xxl={6}>
          {/* <TopProducts data={topProducts} className="h-100" /> */}
          {dataExportMonths && (
            <TotalSales
              className="h-100"
              months={dataExportMonths}
              loading={loading}
            />
          )}
        </Col>
        <Col lg={6} xl={6} xxl={6}>
          {/* <TopProducts data={topProducts} className="h-100" /> */}
          {dataExportMonths && (
            <NotaryMonthlyFee
              className="h-100"
              months={dataExportMonths}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
