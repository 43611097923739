import {
  faBoxTissue,
  faPlusCircle,
  faRetweet,
  faTrashAlt,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import DraftEntry from 'components/models/DraftEntry';
import FaqBasicCard from 'components/pages/knowledge-center/faq/FaqBasicCard';
import AppContext from 'context/Context';
import { faqs } from 'data/faqs-subscription';
import draftEntryHelper from 'helpers/draft-entry-helper';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import {
  Button,
  Card,
  OverlayTrigger,
  ProgressBar,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Colors from 'util/Colors';
import c from 'util/const';
import logger from 'util/logger';

const DarftLayout = ({
  draftEntry,
  createNewDraftEntry,
  removeDraft,
  isDraftSynced,
  refreshDraftFromServer,
  isRefreshingDrafts
}) => {
  const { isRTL } = useContext(AppContext);
  const { userMaster } = useSelector(state => state.app);
  const timezones = c.timezones?.find(item => item?.offset === userMaster?.LocalTimezone);
  const columns = [
    {
      accessor: 'title',
      Header: 'Last updated',
      Cell: rowData => {
        //logger.log("rowData.row.original", rowData.row.original);
        const { id, avatar, color, title, projectName, isCompleted, source } =
          rowData.row.original;
        let timeInHST = title;
        if(timezones?.zone){
          const parsedDate = moment.tz(title, 'MM/DD/YYYY hh:mm A', 'UTC');
          timeInHST = parsedDate.tz(timezones?.zone).format('MM/DD/YYYY hh:mm A');
        }
        return (
          <Flex alignItems="center" className="position-relative">
            <div className="flex-1">
              <h6 className="mb-0 fw-semi-bold">
                <Link
                  className={
                    isCompleted
                      ? 'text-success stretched-link'
                      : 'text-dark stretched-link'
                  }
                  to={
                    '/notary/signing/' + draftEntryHelper.getDarftKeyFromId(id)
                  }
                >
                  {timeInHST}
                  &nbsp; {draftEntryHelper.getSourceIcon(source)}
                </Link>
              </h6>
              {isCompleted ? (
                <p className="fs--2 mb-0 fw-bold text-success">Completed</p>
              ) : (
                <p className="fs--2 fw-bold mb-0 text-500">{projectName}</p>
              )}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'location',
      Header: 'Location',
      headerProps: {
        className: ''
      },
      cellProps: {
        className: ''
      },
      Cell: rowData => {
        const { id, location, isCompleted } = rowData.row.original;
        return (
          <h6 className="mb-0 fw-semi-bold">
            <Link
              className={isCompleted ? 'text-success ' : 'text-dark'}
              to={'/notary/signing/' + draftEntryHelper.getDarftKeyFromId(id)}
            >
              {location?.address_1 && location?.address_1?.length > 0
                ? location?.address_1
                : ''}
              {location?.address_2 && location?.address_2?.length > 0
                ? ', ' + location?.address_2
                : ''}
              {location?.city && location?.city?.length > 0
                ? ', ' + location?.city
                : ''}
              {location?.state && location?.state?.length > 0
                ? ', ' + location?.state
                : ''}
              {location?.zip && location?.zip?.length > 0
                ? '-' + location?.zip
                : ''}
            </Link>
          </h6>
        );
      }
    },
    {
      accessor: 'progress',
      Header: 'Progress',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { isCompleted } = rowData.row.original;
        return (
          <ProgressBar
            now={rowData.row.original.progress}
            style={{ height: 5 }}
            className="rounded-pill align-middle"
            variant={isCompleted ? 'success' : 'progress-gradient'}
          />
        );
      }
    },
    {
      accessor: 'documents',
      Header: 'Docs',
      cellProps: {
        className: 'text-center fw-semi-bold fs--1'
      },
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { isCompleted, documents } = rowData.row.original;
        return (
          <span className={isCompleted ? 'text-success' : ''}>{documents}</span>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Created',
      cellProps: {
        className: 'text-center fw-semi-bold fs--1'
      },
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { isCompleted, date } = rowData.row.original;
        return (
          <span className={isCompleted ? 'text-success' : ''}>{date}</span>
        );
      }
    },
    {
      accessor: 'members',
      Header: 'Signers',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { isCompleted } = rowData.row.original;
        return rowData.row.original.members.length ? (
          <AvatarGroup className="justify-content-center">
            {rowData.row.original.members.map(({ name, id }) => {
              return (
                <OverlayTrigger
                  overlay={<Tooltip id={'tooltip-' + id}>{name}</Tooltip>}
                  key={id}
                  placement="top"
                >
                  <span>
                    <Avatar
                      key={id}
                      name={name}
                      title={name}
                      className="border border-3 rounded-circle border-light"
                      mediaClass={isCompleted ? 'bg-success' : ''}
                    />
                  </span>
                </OverlayTrigger>
              );
            })}
          </AvatarGroup>
        ) : (
          <Flex
            justifyContent="center"
            className="mx-1 fw-bold text-danger fs-0"
          >
            N/A
          </Flex>
        );
      }
    },
    {
      accessor: 'action',
      Header: 'Action',
      disableSortBy: true,
      headerProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        //logger.log(rowData.row.original);
        const { id, isCompleted } = rowData.row.original;
        return (
          <Flex justifyContent="end" alignContent="end">
            {/* {isCompleted && (
              <div className="text-right cursor-pointer">
                <Button
                  variant="outline-success"
                  onClick={() => uploadFinishedDraft(id)}
                  size="sm"
                >
                  {' '}
                  <FontAwesomeIcon icon={faUpload} /> Upload
                </Button>
              </div>
            )} */}
            <div className="text-right cursor-pointer mx-1">
              <Button
                variant="outline-danger"
                onClick={() => removeDraft(id)}
                size="sm"
              >
                {' '}
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </div>
          </Flex>
        );
      }
    }
  ];
  const AddNewSigning = () => {
    return (
      <div className="text-center py-4">
        <h4>
          <FontAwesomeIcon
            color={Colors.gray.lightColor}
            size="5x"
            icon={faBoxTissue}
          />
        </h4>
        <p className="my-3">You don't have any draft entries.</p>
        <p className="text-center">
          <Button
            onClick={() => refreshDraftFromServer()}
            className="mx-1"
            variant="success"
          >
            {isRefreshingDrafts ? (
              <Flex justifyContent={'center'} alignItems="center">
                <Spinner animation="border" size="sm" /> &nbsp; Refreshing...
              </Flex>
            ) : (
              <>
                <FontAwesomeIcon icon={faRetweet} /> Refresh
              </>
            )}
          </Button>
        </p>
      </div>
    );
  };

  return (
    <>
      {draftEntry.length ? (
        <AdvanceTableWrapper
          columns={columns}
          data={draftEntry}
          sortable
          pagination
          perPage={10}
          sortBy={[
            {
              id: 'title',
              desc: true
            }
          ]}
        >
          <Card className="h-100">
            <FalconCardHeader
              title={
                <>
                  Notarization Signing Drafts{' '}
                  <SoftBadge variant="primary"> {draftEntry.length}</SoftBadge>
                </>
              }
              titleTag="h6"
              endEl={
                <>
                  <Button
                    onClick={refreshDraftFromServer}
                    className="mx-1"
                    variant="success"
                  >
                    {isRefreshingDrafts ? (
                      <Flex justifyContent={'center'} alignItems="center">
                        <Spinner animation="border" size="sm" /> &nbsp;
                        Refreshing...
                      </Flex>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faRetweet} /> Refresh
                      </>
                    )}
                  </Button>
                  <Button
                    onClick={createNewDraftEntry}
                    className="mx-1"
                    variant="primary"
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Start New
                  </Button>
                </>
              }
            />
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-light text-800 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  borderless: true,
                  className: 'fs--2 mb-0 overflow-hidden',
                  //sortedIndex: 1,
                  sorted: [{ id: 'title', desc: true }]
                }}
              />
              <div className="my-3 px-3">
                <AdvanceTableFooter
                  rowCount={draftEntry.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </Card.Body>
            {/* <FalconCardFooterLink title="Show all projects" size="sm" /> */}
          </Card>
        </AdvanceTableWrapper>
      ) : isDraftSynced ? (
        <>
          <AddNewSigning />
        </>
      ) : (
        <Flex
          alignItems={'center'}
          justifyContent="center"
          direction={'column'}
        >
          <Spinner animation="border" /> <br />{' '}
          <span>Syncing draft. Please wait...</span>
        </Flex>
      )}
      <div className="mt-3">
        <FaqBasicCard
          faqs={faqs}
          header
          headerText="Frequently asked questions"
          bodyClass="bg-light"
          faqIdentity="Draft page"
        />
      </div>
    </>
  );
};

DarftLayout.propTypes = {
  draftEntry: PropTypes.array.isRequired,
  createNewDraftEntry: PropTypes.func.isRequired
};

export default DarftLayout;
