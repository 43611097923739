import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import c from 'util/const';
import enums from 'util/enums';
import idGenerator from 'util/idGenerator';
import logger from 'util/logger';

const IdentificationForm = ({
  signerData,
  witnessId,
  setIdentityItem,
  setShowIdentityForm
}) => {
  const states = c.states;
  const id_picker_opts = [
    {
      name: enums.ID_TYPES.DRIVERS_LICENSE,
      text: enums.ID_TYPES.DRIVERS_LICENSE
    },
    {
      name: enums.ID_TYPES.MILITARY_ID,
      text: enums.ID_TYPES.MILITARY_ID
    },
    {
      name: enums.ID_TYPES.PASSPORT,
      text: enums.ID_TYPES.PASSPORT
    },
    {
      name: enums.ID_TYPES.STATE_ID,
      text: enums.ID_TYPES.STATE_ID
    },
    {
      name: enums.ID_TYPES.OTHER,
      text: enums.ID_TYPES.OTHER
    },
    {
      name: enums.ID_TYPES.NONE,
      text: enums.ID_TYPES.NONE
    }
  ];
  const [identificationData, setIdentificationData] = useState({
    signer_id: signerData?.signer_id,
    witness_id: witnessId,
    identification_id: idGenerator.getIdentificationId(),
    id_first_name: signerData?.signer_first_name || '',
    id_middle_name: signerData?.signer_middle_name || '',
    id_last_name: signerData?.signer_last_name || '',
    prefix: signerData?.signer_prefix || '',
    suffix: signerData?.signer_sufix || '',
    id_type: enums.ID_TYPES.NONE,
    id_type_other: null,
    id_number: null,
    id_issued_date: null,
    id_expiration_date: null,
    date_of_birth: null,
    id_issuing_state: null,
    id_address_1: null,
    id_address_2: null,
    id_city: null,
    id_state: null,
    id_postal_code: null,
    notes: null,
    scan_vendor: null,
    scan_data: null
  });
  const [showFullName, setShowFullName] = useState(false);
  const handleChange = e => {
    setIdentificationData({
      ...identificationData,
      [e.target.name]: e.target.value
    });
  };

  const setFormFieldValue = (name, value) => {
    setIdentificationData(identificationData => ({
      ...identificationData,
      [name]: moment(value).format('YYYY-MM-DD').toString()
    }));
  };

  const validateAndSetIdentityItem = () => {
    if (!doValidation()) {
      return;
    }
    setIdentityItem(identificationData);
  };

  const doValidation = () => {
    let {
      id_first_name,
      id_last_name,
      id_type,
      id_type_other,
      id_number,
      id_issued_date,
      id_expiration_date,
      date_of_birth,
      id_issuing_state
    } = identificationData;
    let err = [];

    if (id_type === enums.ID_TYPES.NONE) {
      if (id_first_name.length === 0) {
        err.push('Please enter their first name');
      }
      if (id_last_name.length === 0) {
        err.push('Please enter their last name');
      }
    } else {
      if (id_first_name.length === 0) {
        err.push('Please enter their first name');
      }
      if (id_last_name.length === 0) {
        err.push('Please enter their last name');
      }
      if (!id_type) {
        err.push('Please select your identification type');
      } else if (
        id_type === enums.ID_TYPES.OTHER &&
        id_type_other.length === 0
      ) {
        err.push('Please enter your identification type');
      }
      // if(id_number.length === 0){
      //   err.push('Please enter your identification number');
      // }
      // if (!id_issued_date) {
      //   err.push('Please enter your identification issued date');
      // }
      // if (!id_expiration_date) {
      //   err.push('Please enter your identification expiration date');
      // }
      // if(id_issuing_state.length === 0){
      //   err.push('Please enter your identification issuing state');
      // }
    }

    if (err.length !== 0) {
      toast.warning(err[0], {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle,
        toastId: 'identification-error'
      });
    }
    return err.length === 0;
  };

  useEffect(() => {
    logger.log(identificationData);
  }, [identificationData]);

  return (
    <Row className="mb-3 g-3">
      {' '}
      {showFullName && (
        <Form.Group as={Col} sm={12} controlId="prefix">
          <Form.Label> Prefix </Form.Label>{' '}
          <Form.Control
            type="text"
            placeholder="Enter prefix name"
            value={identificationData.prefix || ''}
            name="prefix"
            onChange={handleChange}
          />{' '}
        </Form.Group>
      )}
      <Form.Group as={Col} sm={showFullName ? 12 : 6} controlId="id_first_name">
        <Form.Label> First name * </Form.Label>{' '}
        <Form.Control
          type="text"
          placeholder="Enter first name - (Required)"
          value={identificationData.id_first_name || ''}
          name="id_first_name"
          onChange={handleChange}
        />{' '}
      </Form.Group>
      {showFullName && (
        <Form.Group as={Col} lg={12} controlId="id_middle_name">
          <Form.Label> Middle name </Form.Label>{' '}
          <Form.Control
            type="text"
            placeholder="Enter middle name"
            value={identificationData.id_middle_name || ''}
            name="id_middle_name"
            onChange={handleChange}
          />{' '}
        </Form.Group>
      )}
      <Form.Group as={Col} sm={showFullName ? 12 : 6} controlId="id_last_name">
        <Form.Label> Last name * </Form.Label>{' '}
        <Form.Control
          type="text"
          placeholder="Enter last name - (Required)"
          value={identificationData.id_last_name || ''}
          name="id_last_name"
          onChange={handleChange}
        />{' '}
      </Form.Group>
      {showFullName && (
        <Form.Group as={Col} lg={12} controlId="suffix">
          <Form.Label> Sufix </Form.Label>{' '}
          <Form.Control
            type="text"
            placeholder="Enter name sufix"
            value={identificationData.suffix || ''}
            name="suffix"
            onChange={handleChange}
          />{' '}
        </Form.Group>
      )}
      <Form.Group as={Col} lg={12}>
        <p className="my-0 mb-2 text-end fs--1 text-primary cursor-pointer">
          {' '}
          <span onClick={() => setShowFullName(!showFullName)}>
            {' '}
            {showFullName ? 'Hide' : 'Show'}
            full name{' '}
          </span>
        </p>
      </Form.Group>
      <Form.Group as={Col} sm={6} controlId="id_type">
        <Form.Label> ID type </Form.Label>{' '}
        <Form.Select
          type="text"
          name="id_type"
          value={identificationData.id_type || enums.ID_TYPES.NONE}
          onChange={handleChange}
        >
          <option value=""> Select Id Type </option>{' '}
          {id_picker_opts.map(option => (
            <option value={option.text} key={option.text}>
              {' '}
              {option.name}{' '}
            </option>
          ))}{' '}
        </Form.Select>{' '}
      </Form.Group>
      <Form.Group as={Col} sm={6} controlId="id_number">
        <Form.Label> Identification number </Form.Label>{' '}
        <Form.Control
          type="text"
          placeholder="Enter identification number"
          value={identificationData.id_number || ''}
          name="id_number"
          onChange={handleChange}
        />{' '}
      </Form.Group>
      {identificationData.id_type === enums.ID_TYPES.OTHER && (
        <Form.Group as={Col} sm={12} controlId="id_type_other">
          <Form.Label> ID type name(Other) </Form.Label>{' '}
          <Form.Control
            type="text"
            placeholder="Enter identification number"
            value={identificationData.id_type_other || ''}
            name="id_type_other"
            onChange={handleChange}
          />{' '}
        </Form.Group>
      )}
      <Form.Group as={Col} sm={6} controlId="id_issued_date">
        <Form.Label> ID issue date </Form.Label>{' '}
        <DatePicker
          selected={
            identificationData.id_issued_date
              ? new Date(identificationData.id_issued_date)
              : null
          }
          onChange={date =>
            setFormFieldValue('id_issued_date', date)
          }
          className="form-control"
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          startDate="01/01/1950"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          yearDropdownItemNumber={40}
        />
      </Form.Group>
      <Form.Group as={Col} sm={6} controlId="id_expiration_date">
        <Form.Label> ID expiration date </Form.Label>{' '}
        <DatePicker
          selected={
            identificationData.id_expiration_date
              ? new Date(identificationData.id_expiration_date)
              : null
          }
          onChange={date =>
            setFormFieldValue('id_expiration_date', date)
          }
          className="form-control"
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          startDate="01/01/1950"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          yearDropdownItemNumber={40}
        />
      </Form.Group>
      <Form.Group as={Col} lg={12} controlId="date_of_birth">
        <Form.Label> Date of birth </Form.Label>{' '}
        <DatePicker
          selected={
            identificationData.date_of_birth
              ? new Date(identificationData.date_of_birth)
              : null
          }
          onChange={date =>
            setFormFieldValue('date_of_birth', date)
          }
          className="form-control"
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          startDate="01/01/1950"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          yearDropdownItemNumber={40}
        />
      </Form.Group>
      <Form.Group as={Col} lg={12} controlId="id_address_1">
        <Form.Label> Address line 1 </Form.Label>{' '}
        <Form.Control
          type="text"
          placeholder="Enter address line 1"
          value={identificationData.id_address_1 || ''}
          name="id_address_1"
          onChange={handleChange}
        />{' '}
      </Form.Group>
      <Form.Group as={Col} lg={12} controlId="id_address_2">
        <Form.Label> Address line 2 </Form.Label>{' '}
        <Form.Control
          type="text"
          placeholder="Enter address line 2 - (Optional)"
          value={identificationData.id_address_2 || ''}
          name="id_address_2"
          onChange={handleChange}
        />{' '}
      </Form.Group>
      <Form.Group as={Col} lg={12} controlId="id_city">
        <Form.Label> City </Form.Label>{' '}
        <Form.Control
          type="text"
          placeholder="Enter city name"
          value={identificationData.id_city || ''}
          name="id_city"
          onChange={handleChange}
        />{' '}
      </Form.Group>
      <Form.Group as={Col} sm={6} controlId="id_state">
        <Form.Label> State </Form.Label>{' '}
        <Form.Select
          type="text"
          name="id_state"
          value={identificationData.id_state || ''}
          onChange={handleChange}
        >
          <option value=""> Select state </option>{' '}
          {states.map(option => (
            <option value={option.text} key={option.text}>
              {' '}
              {option.name}{' '}
            </option>
          ))}{' '}
        </Form.Select>{' '}
      </Form.Group>
      <Form.Group as={Col} sm={6} controlId="id_postal_code">
        <Form.Label> Postal code </Form.Label>{' '}
        <Form.Control
          type="text"
          placeholder="Enter postal code"
          value={identificationData.id_postal_code || ''}
          name="id_postal_code"
          onChange={handleChange}
        />{' '}
      </Form.Group>
      <Form.Group as={Col} sm={12} controlId="notes">
        <Form.Label> Notes </Form.Label>{' '}
        <Form.Control
          as="textarea"
          rows={2}
          type="text"
          placeholder="Enter identification notes (Optional)"
          value={identificationData.notes || ''}
          name="notes"
          onChange={handleChange}
        />{' '}
      </Form.Group>
      <hr />
      <Flex justifyContent="center">
        <Button
          className="mx-1"
          variant="secondary"
          onClick={() => setShowIdentityForm(false)}
        >
          Close{' '}
        </Button>{' '}
        <Button variant="primary" onClick={() => validateAndSetIdentityItem()}>
          {' '}
          Save{' '}
        </Button>{' '}
      </Flex>{' '}
    </Row>
  );
};
export default IdentificationForm;
